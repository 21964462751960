.searchBox {
    position: fixed;
    bottom: 0%;
    left: 20px;
    transform: translate(0%, -50%);
    background: #c3b1e1;
    height: 58px;
    border-radius: 40px;
    padding: 10px;
  }
  
  .searchBox:hover > .searchText {
    width: 210px;
    padding: 0 9px;
  }
  
  .searchBox:hover > .searchBtn {
    background: #1270c8;
    margin-right: 5px;
  }
  .searchBtn {
    color: black;
    float: left;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #1270c8;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.4s;
    cursor: pointer;
    text-decoration: none;
  }
  
  .searchText {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: white;
    font-size: 20px;
    font-weight: 600;
    transition: 0.4s;
    line-height: 40px;
    width: 0px;
    caret-color: transparent;
    cursor: pointer;
    /* width: 250px; */
  }
  .searchText {
    background-color: #1270c8;
    border-radius: 40px;
    /* text-align: center; */
  }
  