.image {
    object-fit: fill;
    /* padding: 10px; */
  }
  
  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 1rem 0;
    list-style-type: none;
  }
  .slick-dots li {
    margin: 0 0.25rem;
  }
  .slick-dots button {
    display: block;
    width: 0.5rem;
    height: 0.5rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: orange;
    text-indent: -9999px;
  }
  .slick-dots li button {
    background-color: grey !important;
    width: 2px  !important;
    height: 2px !important;
  }
  
  .slick-dots li.slick-active button {
    background-color: #1976d2 !important;
  }
  
  @media screen and (max-width: 600px) {
    .image {
      /* height: 329px; */
    }
  }
  @media (min-width: 601px) and (max-width: 820px) {
    .image {
      /* height: 400px; */
    }
  }
  


  /* pagination */
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 1rem;
}

.pagination button {
    width: 40px;
    height: 40px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    margin: 0 10px;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    /* background: grey; */
    color: #101010;
    border: 1px solid #1976d2;
    /* border-color: 101010; */
}

.pagination button.active {
    font-weight: 900;
    /* border-color: #101010; */
    background: #1976d2;
    color: #101010;
}



/* blog */

.markdownBlog * {
  /* text-align: justify; */
}

.markdownBlog p:empty {
  height: 30px !important;
  /* text-align: justify !important; */
}
.markdownBlog p strong {
  font-weight: 600 !important;
  font-size: 22px;
  /* height: 30px !important; */
  /* text-align: justify !important; */
}
.markdownBlog p span {
  font-size: 18px !important;
}

.markdownBlog p {
  text-align: justify;
}
.markdownBlog h2 * {
  font-weight: 600;
  font-size: 28px;
  text-align: left !important;
}
.markdownBlog h3 * {
  font-weight: 500;
  font-size: 25px;
}
.markdownBlog h4 * {
  font-weight: 500;
  font-size: 20px;
}



@media (max-width: 767px) {

  .markdownBlog * {
    /* text-align: left; */
  }
  .markdownBlog h2 * {
    font-weight: 600;
    font-size: 28px;
    text-align: left !important;
  }
}



